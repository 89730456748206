import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AnimationService} from 'src/app/conversation/animation/animation.service';
import {Subscription} from 'rxjs';
import {ChatService, Message} from 'src/app/conversation/chat/chat.service';
import {ChatState} from 'src/app/conversation/chat/chat-state.enum';

@Component({
    selector: 'app-chat',
    template: `
        <div class="component-wrapper"
             [ngClass]="{'disabled': isHidden(), 'enabled-full': isExpanded(), 'enabled-small': isRolled()}"
             (mouseenter)="hover(true)" (mouseleave)="hover(false)">
            <mat-toolbar [ngClass]="{'animated-background': hasNewUnreadMessage(),
                                        'white-shadow': hasNewUnreadMessage(),
                                        'expanded-vertically': isRolled(),
                                        'collapsed-vertically': isExpanded(),
                                        'chat-header-hover': isHeaderHovered() && !hasNewUnreadMessage()}"
                         (mouseenter)="hoverHeader(true)" (mouseleave)="hoverHeader(false)"
                         color="primary" class="chat-header mat-elevation-z24" (click)="toggleChat()">
                <mat-icon *ngIf="isHeaderHovered() && isExpanded()">arrow_forward</mat-icon>
                <mat-icon *ngIf="isHeaderHovered() && isRolled()">arrow_back</mat-icon>
                <mat-icon *ngIf="!isHeaderHovered()">chat</mat-icon>
            </mat-toolbar>
            <app-chat-messages [ngClass]="{'hidden': !isExpanded()}" class="mat-elevation-z24"></app-chat-messages>
            <app-chat-form [ngClass]="{'hidden': !isExpanded()}"></app-chat-form>
        </div>
    `,
    styleUrls: ['./chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ChatComponent implements OnInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    private state: ChatState;
    private hovered: boolean;
    private headerHovered: boolean;
    private newUnreadMessage: boolean;

    constructor(private animationService: AnimationService,
                private chatService: ChatService,
                private changeDetectorRef: ChangeDetectorRef) {
        this.reset();
    }

    ngOnInit(): void {
        this.subscriptions.push(this.chatService.messagesEvent.subscribe(this.onNewMessage.bind(this)));
        this.subscriptions.push(this.animationService.animateExpandChat.subscribe(this.setState.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onNewMessage(message: Message): void {
        if (message.peer !== this.chatService.getYouPeer() && !this.hovered) {
            if (!this.newUnreadMessage) {
                this.newUnreadMessage = true;
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    isExpanded(): boolean {
        return this.state === ChatState.EXPANDED;
    }

    isRolled(): boolean {
        return this.state === ChatState.COLLAPSED;
    }

    read(): void {
        this.newUnreadMessage = false;
    }

    hover(mouseEntered: boolean): void {
        if (this.state === ChatState.EXPANDED) {
            this.read();
        }
        this.hovered = mouseEntered;
    }

    hoverHeader(mouseEntered: boolean): void {
        this.headerHovered = mouseEntered;
    }

    hasNewUnreadMessage(): boolean {
        return this.newUnreadMessage;
    }

    isHovered(): boolean {
        return this.hovered;
    }

    isHeaderHovered(): boolean {
        return this.headerHovered;
    }

    isHidden(): boolean {
        return this.state === ChatState.HIDDEN;
    }

    setState(state: ChatState): void {
        if (state === ChatState.HIDDEN) {
            this.reset();
        } else {
            this.state = state;
        }
    }

    toggleChat(): void {
        const newState = this.state === ChatState.COLLAPSED ? ChatState.EXPANDED : ChatState.COLLAPSED;
        this.animationService.expandChat(newState);
        if (newState === ChatState.EXPANDED) {
            this.read();
        }
    }

    private reset(): void {
        this.state = ChatState.HIDDEN;
        this.hovered = false;
        this.headerHovered = false;
        this.newUnreadMessage = false;
    }

}
