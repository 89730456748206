import {ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fadeAnimation} from '../app.animations';
import {EntranceService, EntranceWay} from 'src/app/entrance/entrance.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EntranceModel} from 'src/app/entrance/entrance.model';
import {EntranceDataValidator} from 'src/app/validator/entrance-data.validator';

@Component({
    selector: 'app-waiting-room',
    animations: [fadeAnimation],
    template: `
        <div class="vertical-center">
            <div class="wrapper">
                <input
                    autocomplete="off"
                    placeholder="Your name"
                    class="input"
                    [(ngModel)]="peerName"
                    [maxLength]="getMaxLength()"
                    (keyup.enter)="switchOrSend()"
                    tabindex="1"
                    #nameField>
                <div class="input input-wrapper" *ngIf="joiningConversation()">
                    <span *ngIf="conversationIdInputDisabled" class="input-prefix">Conversation ID:</span>
                    <input
                        autocomplete="off"
                        placeholder="Conversation ID"
                        class="raw-input"
                        [(ngModel)]="conversationId"
                        [disabled]="conversationIdInputDisabled"
                        [maxLength]="getMaxLength()"
                        (keyup.enter)="switchOrSend()"
                        tabindex="2"
                        matInput
                        #conversationIdField>
                </div>
                <div class="nav-button-wrapper">
                    <button class="nav-button button-outline" mat-raised-button routerLink="" tabindex="4">Back</button>
                    <button
                        class="nav-button button-solid"
                        mat-raised-button
                        (click)="connect()"
                        [disabled]="!validForm()"
                        [ngClass]="{'slide-animation': shouldAnimate || validForm()}"
                        tabindex="3">{{ callToActionButtonLabel }}
                    </button>
                </div>

            </div>
        </div>
    `,
    styleUrls: ['./waiting-room.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaitingRoomComponent implements OnInit {

    @ViewChild('nameField')
    private nameField: ElementRef;

    @ViewChild('conversationIdField')
    private conversationIdField: ElementRef;

    private entranceWay: EntranceWay;
    conversationId;
    conversationIdInputDisabled = false;
    peerName: string;
    shouldAnimate = false;
    callToActionButtonLabel: string;

    constructor(private entranceService: EntranceService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.nameField.nativeElement.focus();
        this.entranceWay = this.route.snapshot.data['entranceWay'];
        this.callToActionButtonLabel = this.entranceWay === EntranceWay.NEW ? 'Create' : 'Join';
        console.log(`[DEBUG] - entrance way: ${EntranceWay[this.entranceWay]}`);

        this.route.params.subscribe(params => {
            const id = params['id'];
            if (EntranceDataValidator.validateConversationId(id)) {
                this.conversationId = id;
                this.conversationIdInputDisabled = true;
            }
        });
    }

    joiningConversation(): boolean {
        return EntranceWay.JOIN === this.entranceWay;
    }

    connect() {
        if (!this.joiningConversation()) {
            this.conversationId = this.generateNumber();
        }
        this.entranceService.entranceData = new EntranceModel(this.peerName, this.conversationId);
        this.router.navigate(['/conversation', this.conversationId]).then();
    }

    private generateNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }

    validForm(): boolean {
        let result = EntranceDataValidator.validateName(this.peerName);
        if (this.joiningConversation()) {
            result = result && EntranceDataValidator.validateConversationId(this.conversationId);
        }

        if (result) {
            this.shouldAnimate = true;
        }

        return result;
    }

    getMaxLength(): number {
        return EntranceDataValidator.inputLengthLimit;
    }

    switchOrSend() {
        if (this.validForm()) {
            this.connect();
        } else if (!EntranceDataValidator.validateName(this.peerName)) {
            this.nameField.nativeElement.focus();
        } else if (this.joiningConversation() && !this.conversationIdInputDisabled) {
            this.conversationIdField.nativeElement.focus();
        }
    }
}
