import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Peer} from 'src/app/conversation/peers/peer';
import {Subscription} from 'rxjs';
import {EntranceService} from '../../entrance/entrance.service';
import {AnimationService} from 'src/app/conversation/animation/animation.service';
import {PeersService} from './peers.service';
import {muteReceivingAudio, muteSharingAudio} from '@consdata/screensharing-client-lib';

@Component({
    selector: 'app-peers',
    template: `
        <div class="component-wrapper"
             [ngClass]="{'scaled-down': peersScaledDown,
                        'link-expanded': isLinkExpanded}">
            <div class="peer-wrapper" *ngFor="let peer of peers">
                <div
                    class="peer"
                    [ngClass]="{'muted': peer.muted }"
                    (click)="toggleMute(peer)"
                >
                    <span class="peer-letter">{{ peer.name.substr(0, 1) }}</span>
                    <mat-icon *ngIf="!peer.me" class="mute-icon">volume_off</mat-icon>
                    <mat-icon *ngIf="peer.me" class="mute-icon">voice_over_off</mat-icon>
                </div>
                <span class="peer-label">{{ peer.name }}{{ peer.me ? ' (me)' : '' }}</span>
            </div>
        </div>
    `,
    styleUrls: ['./peers.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class PeersComponent implements OnInit, OnDestroy {

    private readonly subscriptions: Subscription[] = [];
    peers: Peer[] = [];
    peersScaledDown = false;
    isLinkExpanded = false;

    constructor(private entranceService: EntranceService,
                private peersService: PeersService,
                private changeDetectorRef: ChangeDetectorRef,
                private animationService: AnimationService) {
        this.peersChanged = this.peersChanged.bind(this);
    }

    ngOnInit() {
        this.peersService.addMe(this.entranceService.entranceData.peerName);
        this.peers = this.peersService.peers;

        this.subscriptions.push(this.peersService.onChange.subscribe(this.peersChanged));
        this.subscriptions.push(this.animationService.animateScreenSharingSubject.subscribe(isScreensharingActive => {
            this.peersScaledDown = isScreensharingActive;
            this.changeDetectorRef.detectChanges();
        }));
        this.subscriptions.push(this.animationService.animateLinkSubject.subscribe(isLinkExpanded => {
            this.isLinkExpanded = isLinkExpanded;
            if (this.peersScaledDown) {
                this.changeDetectorRef.detectChanges();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleMute(peer: Peer) {
        peer.muted = !peer.muted;

        if (peer.me) {
            muteSharingAudio(peer.muted);
        } else {
            this.peers.filter(p => !p.me).forEach(p => p.muted = peer.muted);
            muteReceivingAudio(peer.muted);
        }

        this.changeDetectorRef.detectChanges();
    }

    private peersChanged() {
        muteReceivingAudio(false);
        this.changeDetectorRef.detectChanges();
    }
}
