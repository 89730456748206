import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {EntranceService} from 'src/app/entrance/entrance.service';
import {EntranceDataValidator} from 'src/app/validator/entrance-data.validator';

@Injectable({
    providedIn: 'root'
})
export class ConversationGuard implements CanActivate {

    constructor(private router: Router,
                private entranceService: EntranceService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.validEntrance()) {
            return true;
        }
        this.router.navigate(this.getWaitingRoomPath(route.params['id'])).then();
        return false;
    }

    private validEntrance(): boolean {
        return this.entranceService.entranceData.peerName !== undefined
            && this.entranceService.entranceData.conversationId !== undefined;
    }

    private getWaitingRoomPath(conversationId: string) {
        const waitingRoomPathPart = '/join';
        if (EntranceDataValidator.validateConversationId(conversationId)) {
            return [waitingRoomPathPart, conversationId];
        }
        return [waitingRoomPathPart];
    }
}
