import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SupportService} from 'src/app/support/support.service';

@Component({
    selector: 'app-brick',
    template: `
        <div *ngIf="!supportService.isSupported"
             class="brick warning"
             [matTooltip]="supportTooltipText"
             matTooltipClass="warning-tooltip">
            <div class="text">{{ notSupportedNotification }}</div>
        </div>
        <div *ngIf="supportService.isSupported && !supportService.isMicConnected"
             class="brick notification">
            <div class="text">{{ micNotConnected }}</div>
        </div>
    `,
    styleUrls: ['brick.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class BrickComponent implements OnInit {

    notSupportedNotification = 'Your browser is not supported!';
    supportTooltipText = `Fully supported browsers:
                          Chrome version ${SupportService.CHROME_MIN_VER} or above;
                          Firefox version ${SupportService.FIREFOX_MIN_VER} or above;
                          Edge version ${SupportService.EDGE_MIN_VER} or above`;
    micNotConnected = 'Microphone not connected!';

    constructor(public supportService: SupportService) {
    }

    ngOnInit() {
    }

}
