import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'app-confirm-dialog',
    template: `
        <h1 mat-dialog-title>Przychodzące połączenie</h1>
        <div mat-dialog-content align="center">
            <p>{{ data.message }}</p>
        </div>
        <div mat-dialog-actions align="end">
            <button mat-raised-button color="warn" [mat-dialog-close]="false">Odrzuć</button>
            <button mat-raised-button color="primary" [mat-dialog-close]="true">Akceptuj</button>
        </div>
    `,
})
export class IncomingCallDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: {message: string}) {
    }

    ngOnInit(): void {
    }
}
