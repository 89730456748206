import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-navbar',
    template: `
        <mat-toolbar color="primary" class="header mat-elevation-z24">
            <div class="navbar-wrapper">
                <div class="logo-wrapper">
                    <div class="logo-text">
                        Consdata IO
                    </div>
                </div>
                <app-brick></app-brick>
            </div>
            <app-copy *ngIf="inConversation"></app-copy>
        </mat-toolbar>
    `,
    styleUrls: ['navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class NavbarComponent implements OnInit {

    inConversation = false;

    constructor(private router: Router) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((value: NavigationEnd) => {
            this.inConversation = value.urlAfterRedirects.indexOf('conversation') > -1;
        });
    }

    ngOnInit() {
    }

}
