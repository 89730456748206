import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AnimationService} from '../conversation/animation/animation.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-info',
    template: `
        <div *ngIf="showInfoBar" class="wrapper">
            <div class="content">
                <span class="copyright">
                    <span>© Consdata 2019, </span>
                    <a href="https://consdata.com" target="_blank">consdata.com</a>
                </span>
                <a href="https://twitter.com/consdata" target="_blank" class="icon ion-logo-twitter"></a>
                <a href="https://facebook.com/consdatacom" target="_blank" class="icon ion-logo-facebook"></a>
                <a href="https://linkedin.com/company/consdata" target="_blank" class="icon ion-logo-linkedin"></a>
            </div>
        </div>
    `,
    styleUrls: ['./info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent implements OnInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];
    public showInfoBar = true;

    constructor(private animationService: AnimationService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscriptions.push(this.animationService.animateScreenSharingSubject.subscribe(isScreensharingActive => {
            this.showInfoBar = !isScreensharingActive;
            this.changeDetectorRef.detectChanges();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
