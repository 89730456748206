import {Injectable, OnDestroy} from '@angular/core';
import {Peer} from 'src/app/conversation/peers/peer';
import {Subject, Subscription} from 'rxjs';
import {EntranceService} from 'src/app/entrance/entrance.service';
import {sendMessage} from '@consdata/screensharing-client-lib';
import {CallbackService} from 'src/app/conversation/callback/callback.service';

@Injectable({
    providedIn: 'root'
})
export class ChatService implements OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    public messagesEvent = new Subject<Message>();

    constructor(private entranceService: EntranceService,
                callbackService: CallbackService) {
        this.subscriptions.push(callbackService.newChatMessage.subscribe(this.receiveMessage.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public sendMessage(message: string) {
        this.messagesEvent.next(new Message(
            this.getYouPeer(),
            message,
            true
        ));
        sendMessage(message);
    }

    public receiveMessage(message: { from: string, message: string }) {
        this.messagesEvent.next(new Message(
            new Peer(message.from, false),
            message.message,
            false
        ));
    }

    public getYouPeer(): Peer {
        return new Peer(this.entranceService.entranceData.peerName, false, true);
    }
}

export class Message {

    constructor(public peer: Peer, public message: string, public read: boolean) {

    }
}
