import * as XRegExp from 'xregexp';

export class EntranceDataValidator {

    public static readonly inputLengthLimit = 20;

    public static validateConversationId(conversationId: string) {
        const regex = `^[\\w]{1,${this.inputLengthLimit}}$`;
        return conversationId !== undefined && XRegExp(regex).test(conversationId);
    }

    public static validateName(name: string) {
        const regex = `^[\\w\\p{L}]{1,${this.inputLengthLimit}}$`;
        return name !== undefined && XRegExp(regex, 'u').test(name);
    }
}
