import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {MatDialog, MatSnackBar} from '@angular/material';
import {MessageGenerator, NotificationMessage} from 'src/app/conversation/tracks/message-generator';
import {map} from 'rxjs/operators';
import {IncomingCallDialogComponent} from 'src/app/conversation/dialog/incoming-call-dialog.component';
import {MediaCommunication} from 'src/app/conversation/tracks/media-communication';

@Injectable({
    providedIn: 'root',
})
export class CallbackService implements OnDestroy {
    public readonly sessionEstablished = new Subject<MediaCommunication>();
    public readonly sessionEnded = new Subject<MediaCommunication>();
    public readonly errorOccurred = new Subject<string>();
    public readonly joinedMembers = new Subject<string[]>();
    public readonly leftMembers = new Subject<string[]>();
    public readonly callRejected = new Subject<MediaCommunication>();

    public readonly acceptedSharingMedia = new Subject<string[]>();

    public readonly newChatMessage = new Subject<{from: string, message: string}>();

    private readonly subscriptions: Subscription[] = [];

    constructor(private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private zone: NgZone) {
        this.handleSignalingError = this.handleSignalingError.bind(this);
        this.handleCallRejected = this.handleCallRejected.bind(this);
        this.subscriptions.push(this.errorOccurred.subscribe(this.handleSignalingError));
        this.subscriptions.push(this.callRejected.subscribe(this.handleCallRejected));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public async handleCallRejected(media: MediaCommunication): Promise<void> {
        return this.openAlert(MessageGenerator.generateRejectedCallMessage(Object.keys(media)));
    }

    private handleSignalingError(errorCode: string) {
        return this.openAlert(MessageGenerator.generateErrorMessage(errorCode));
    }

    private async openConfirm(messageToShow: string): Promise<boolean> {
        const dialog = this.zone.run(() => this.dialog.open(IncomingCallDialogComponent, {
            disableClose: true,
            autoFocus: true,
            data: {
                message: messageToShow
            }
        }));
        return dialog.afterClosed().pipe(map(v => v as boolean)).toPromise();
    }

    private openAlert(messageToShow: NotificationMessage): Promise<void> {
        const snackBarRef = this.zone.run(() => this.snackBar.open(messageToShow.message, null, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: messageToShow.duration,
            panelClass: 'snackbar-border'
        }));

        return snackBarRef.afterDismissed().pipe(map(() => null)).toPromise();
    }
}
