import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {EntranceService} from '../entrance/entrance.service';
import {AnimationService} from 'src/app/conversation/animation/animation.service';

@Component({
    selector: 'app-copy',
    template: `
        <div class="wrapper" (mouseenter)="hover(true)" (mouseleave)="hover(false)" (click)="copy()" mat-ripple>
            <span class="label">Click to copy link</span>
            <mat-icon>link</mat-icon>
        </div>
    `,
    styleUrls: ['copy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyComponent implements OnInit {

    constructor(private entranceService: EntranceService,
                private animationService: AnimationService) {
    }

    ngOnInit() {
    }

    copy(): void {
        const textarea = this.createFakeTextArea();
        document.documentElement.appendChild(textarea);
        textarea.value = this.createLink();
        textarea.select();
        document.execCommand('copy');
        if (textarea && textarea.parentNode) {
            textarea.parentNode.removeChild(textarea);
        }
    }

    private createLink(): string {
        const id = this.entranceService.entranceData.conversationId;
        return `${window.location.origin}/join/${id}`;
    }

    private createFakeTextArea(): HTMLTextAreaElement {
        const textarea = document.createElement('textarea');
        textarea.style.height = '0px';
        textarea.style.left = '-100px';
        textarea.style.opacity = '0';
        textarea.style.position = 'fixed';
        textarea.style.top = '-100px';
        textarea.style.width = '0px';
        return textarea;
    }

    hover(isExpanded: boolean) {
        this.animationService.animateLink(isExpanded);
    }
}
