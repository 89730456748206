import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CallbackService} from 'src/app/conversation/callback/callback.service';
import {Medium} from 'src/app/conversation/tracks/medium';
import {CommunicationUtil} from 'src/app/conversation/tracks/communication';
import {ChatState} from 'src/app/conversation/chat/chat-state.enum';

@Injectable({
    providedIn: 'root'
})
export class AnimationService implements OnDestroy {

    public animateScreenSharingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public animateLinkSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public animateExpandChat: BehaviorSubject<ChatState> = new BehaviorSubject<ChatState>(ChatState.HIDDEN);


    private subscriptions: Subscription[] = [];

    constructor(private callbackService: CallbackService) {
        this.subscriptions.push(this.callbackService.sessionEstablished.subscribe(media => {
            const screenSharingCommunication = media[Medium[Medium.SCREENSHARING]];
            if (CommunicationUtil.isReceiving(screenSharingCommunication)) {
                this.animateScreenSharingSubject.next(true);
            }
        }));
        this.subscriptions.push(this.callbackService.sessionEnded.subscribe(media => {
            const screenSharingCommunication = media[Medium[Medium.SCREENSHARING]];
            if (CommunicationUtil.isReceiving(screenSharingCommunication)) {
                this.animateScreenSharingSubject.next(false);
            }
        }));
    }

    animateLink(isExpanded: boolean) {
        this.animateLinkSubject.next(isExpanded);
    }

    expandChat(expand: ChatState) {
        this.animateExpandChat.next(expand);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
