import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {EntranceDataValidator} from 'src/app/validator/entrance-data.validator';

@Injectable({
    providedIn: 'root'
})
export class WaitingRoomGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (EntranceDataValidator.validateConversationId(route.params['id'])) {
            return true;
        }
        this.router.navigate(['/join']).then();
        return false;
    }

}
