import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {call, hangup} from '@consdata/screensharing-client-lib';
import {Subscription} from 'rxjs';
import {Medium} from 'src/app/conversation/tracks/medium';
import {CallbackService} from 'src/app/conversation/callback/callback.service';
import {SignalingError} from 'src/app/conversation/tracks/signaling-error';
import {Router} from '@angular/router';
import {AnimationService} from 'src/app/conversation/animation/animation.service';
import {PeersService} from '../peers/peers.service';
import {Communication, CommunicationUtil} from 'src/app/conversation/tracks/communication';
import {MediaCommunication} from './media-communication';


@Component({
    selector: 'app-track-selector',
    template: `
        <div class="wrapper" [class.scaled-down]="scaledDownTrack">
            <p class="wrapper-filler"></p>
            <div class="content">
                <button class="button button-outline"
                        [matTooltip]="audioSharing ? 'End audio call' : 'Audio call'"
                        [matTooltipDisabled]="scaledDownTrack"
                        matTooltipClass="tooltip"
                        mat-raised-button
                        [ngClass]="{
                            'button-connected': audioSharing,
                            'button-disconnected': !audioSharing,
                            'button-loading': audioLoading
                        }"
                        (click)="toggleAudio()"
                        [disabled]="audioLoading || roomIsEmpty">
                    <mat-icon [ngClass]="{'mat-icon-hidden': !audioSharing,'mat-icon-showed': audioSharing}">
                        mic
                    </mat-icon>
                    <mat-icon [ngClass]="{'mat-icon-hidden': audioSharing,'mat-icon-showed': !audioSharing}">
                        mic_off
                    </mat-icon>
                </button>
                <button class="button button-outline"
                        [matTooltip]="screenSharingSharing ? 'Stop sharing my screen' : 'Share my screen'"
                        [matTooltipDisabled]="scaledDownTrack"
                        matTooltipClass="tooltip"
                        mat-raised-button
                        [ngClass]="{
                            'button-connected': screenSharingSharing,
                            'button-disconnected': !screenSharingSharing,
                            'button-loading': screenSharingLoading
                        }"
                        (click)="toggleScreenSharing()"
                        [disabled]="screenSharingLoading || roomIsEmpty">
                    <mat-icon
                        [ngClass]="{'mat-icon-hidden': !screenSharingSharing,'mat-icon-showed': screenSharingSharing}">
                        screen_share
                    </mat-icon>
                    <mat-icon
                        [ngClass]="{'mat-icon-hidden': screenSharingSharing,'mat-icon-showed': !screenSharingSharing}">
                        stop_screen_share
                    </mat-icon>
                </button>
                <button class="button button-outline button-call-end"
                        matTooltip="Exit conversation"
                        [matTooltipDisabled]="scaledDownTrack"
                        matTooltipClass="tooltip"
                        mat-raised-button
                        (click)="exitConversation()">
                    <mat-icon class="mat-icon-hidden">call_end</mat-icon>
                    <mat-icon class="mat-icon-showed">call</mat-icon>
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./track-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class TrackSelectorComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];

    screenSharingSharing = false;
    screenSharingReceiving = false;
    screenSharingLoading = false;

    audioSharing = false;
    audioReceiving = false;
    audioLoading = false;

    scaledDownTrack = false;

    roomIsEmpty = true;

    private firstAudioAutoConnection = true;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private callbackService: CallbackService,
                private animationService: AnimationService,
                private peersService: PeersService,
                private router: Router) {
        this.sessionEstablished = this.sessionEstablished.bind(this);
        this.sessionEnded = this.sessionEnded.bind(this);
        this.callRejected = this.callRejected.bind(this);
        this.handleSignalingError = this.handleSignalingError.bind(this);
        this.automaticallyCallToExistingMembers = this.automaticallyCallToExistingMembers.bind(this);
        this.membersChanged = this.membersChanged.bind(this);
        this.notifyAboutSharingMedia = this.notifyAboutSharingMedia.bind(this);
    }

    ngOnInit() {
        this.subscriptions.push(this.callbackService.sessionEstablished.subscribe(this.sessionEstablished));
        this.subscriptions.push(this.callbackService.sessionEnded.subscribe(this.sessionEnded));
        this.subscriptions.push(this.callbackService.callRejected.subscribe(this.callRejected));
        this.subscriptions.push(this.callbackService.errorOccurred.subscribe(this.handleSignalingError));
        this.subscriptions.push(this.callbackService.joinedMembers.subscribe(this.automaticallyCallToExistingMembers));
        this.subscriptions.push(this.animationService.animateScreenSharingSubject.subscribe(areScaledDown => {
            this.scaledDownTrack = areScaledDown;
            this.changeDetectorRef.detectChanges();
        }));
        this.subscriptions.push(this.peersService.onChange.subscribe(this.membersChanged));

        this.subscriptions.push(this.callbackService.acceptedSharingMedia.subscribe(this.notifyAboutSharingMedia));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    toggleAudio(): void {
        const media = {[Medium[Medium.AUDIO]]: Communication[Communication.SHARE_AND_RECEIVE]};
        if (this.audioSharing) {
            hangup(media);
            this.audioLoading = false;
        } else {
            call(this.calculateActiveMedia(media));
            this.audioLoading = true;
        }
    }

    calculateActiveMedia(media: MediaCommunication): MediaCommunication {
        if (this.screenSharingReceiving) {
            media[Medium[Medium.SCREENSHARING]] = Communication[Communication.SHARE_AND_RECEIVE];
        }

        if (this.audioSharing || this.audioReceiving) {
            media[Medium[Medium.AUDIO]] = Communication[Communication.SHARE_AND_RECEIVE];
        }

        return media;
    }

    toggleScreenSharing(): void {
        const media = {[Medium[Medium.SCREENSHARING]]: Communication[Communication.SHARE]};
        if (this.screenSharingSharing) {
            hangup(media);
            this.screenSharingLoading = false;
        } else {
            call(this.calculateActiveMedia(media));
            this.screenSharingLoading = true;
        }
    }

    exitConversation(): void {
        this.screenSharingLoading = false;
        this.audioLoading = false;

        this.router.navigate(['/']).then();
    }

    sessionEstablished(media: MediaCommunication) {
        console.log(`established ${JSON.stringify(media)} session`);

        this.onAudioSessionEstablished(media[Medium[Medium.AUDIO]]);
        this.onScreenSharingSessionEstablished(media[Medium[Medium.SCREENSHARING]]);

        this.changeDetectorRef.detectChanges();
    }

    private onScreenSharingSessionEstablished(screenSharingCommunication: string) {
        if (CommunicationUtil.isReceiving(screenSharingCommunication)) {
            this.screenSharingReceiving = true;
            this.screenSharingLoading = false;
        }

        if (CommunicationUtil.isSharing(screenSharingCommunication)) {
            this.screenSharingSharing = true;
            this.screenSharingLoading = false;
        }
    }

    private onAudioSessionEstablished(audioCommunication: string) {
        if (CommunicationUtil.isReceiving(audioCommunication)) {
            this.audioReceiving = true;
            this.audioLoading = false;
        }

        if (CommunicationUtil.isSharing(audioCommunication)) {
            this.audioSharing = true;
            this.audioLoading = false;
        }
    }

    sessionEnded(media: MediaCommunication) {
        console.log(`ended ${JSON.stringify(media)} session`);

        this.onAudioSessionEnded(media[Medium[Medium.AUDIO]]);
        this.onScreenSharingEnded(media[Medium[Medium.SCREENSHARING]]);

        this.changeDetectorRef.detectChanges();
    }

    private onScreenSharingEnded(screenSharingCommunication: string) {
        if (CommunicationUtil.isReceiving(screenSharingCommunication)) {
            this.screenSharingReceiving = false;
            this.screenSharingLoading = false;
        }

        if (CommunicationUtil.isSharing(screenSharingCommunication)) {
            this.screenSharingSharing = false;
            this.screenSharingLoading = false;
        }
    }

    private onAudioSessionEnded(audioCommunication: string) {
        if (CommunicationUtil.isReceiving(audioCommunication)) {
            this.audioReceiving = false;
            this.audioLoading = false;
        }

        if (CommunicationUtil.isSharing(audioCommunication)) {
            this.audioSharing = false;
            this.audioLoading = false;
        }
    }

    private handleSignalingError(errorCode: string) {
        if (errorCode === SignalingError[SignalingError.NOT_IN_ROOM]) {
            const sessionToEnd = new MediaCommunication();
            if (this.audioLoading) {
                sessionToEnd[Medium[Medium.AUDIO]] = Communication[Communication.RECEIVE];
            }
            if (this.screenSharingLoading) {
                sessionToEnd[Medium[Medium.SCREENSHARING]] = Communication[Communication.RECEIVE];
            }
            this.sessionEnded(sessionToEnd);
        }
    }

    callRejected(media: MediaCommunication) {
        const audioCommunication = media[Medium[Medium.AUDIO]];
        if (CommunicationUtil.isReceiving(audioCommunication)) {
            this.audioLoading = false;
        }

        const screenSharingCommunication = media[Medium[Medium.SCREENSHARING]];
        if (CommunicationUtil.isReceiving(screenSharingCommunication)) {
            this.screenSharingLoading = false;
        }

        this.changeDetectorRef.detectChanges();
    }

    automaticallyCallToExistingMembers(members: string[]) {
        if (members.length >= 1) {
            this.roomIsEmpty = false;
            if (this.firstAudioAutoConnection) {
                this.toggleAudio();
            }
            this.changeDetectorRef.detectChanges();
        }
        this.firstAudioAutoConnection = false;
    }

    membersChanged() {
        if (!this.roomIsEmpty && this.peersService.isRoomEmptyExceptMe()) {
            this.roomIsEmpty = true;
            this.changeDetectorRef.detectChanges();
        } else if (this.roomIsEmpty) {
            this.roomIsEmpty = false;
            this.changeDetectorRef.detectChanges();
        }
    }

    notifyAboutSharingMedia(media: string[]): void {
        let changed = false;
        if (!this.screenSharingSharing && media.indexOf(Medium[Medium.SCREENSHARING]) >= 0) {
            this.screenSharingLoading = true;
            changed = true;
        }
        if (!this.audioSharing && media.indexOf(Medium[Medium.AUDIO]) >= 0) {
            this.audioLoading = true;
            changed = true;
        }
        if (changed) {
            this.changeDetectorRef.detectChanges();
        }
    }
}
