import {ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {EntranceService} from 'src/app/entrance/entrance.service';
import {hangup, joinRoom, leaveCurrentRoom} from '@consdata/screensharing-client-lib';
import {CallbackService} from 'src/app/conversation/callback/callback.service';
import {Medium} from 'src/app/conversation/tracks/medium';
import {PeersService} from 'src/app/conversation/peers/peers.service';
import {Communication, CommunicationUtil} from 'src/app/conversation/tracks/communication';
import {AnimationService} from 'src/app/conversation/animation/animation.service';
import {MediaCommunication} from 'src/app/conversation/tracks/media-communication';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {SignalingError} from 'src/app/conversation/tracks/signaling-error';
import {ChatService} from 'src/app/conversation/chat/chat.service';
import {ChatState} from 'src/app/conversation/chat/chat-state.enum';

@Component({
    selector: 'app-conversation',
    template: `
        <div class="vertical-center">
            <div [class.hide-curtain]="isScreenSharingActive" class="spacer"></div>
            <app-peers></app-peers>
            <app-video></app-video>
            <app-track-selector [class.hide-curtain]="isScreenSharingActive"></app-track-selector>
        </div>
    `,
    styleUrls: ['conversation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PeersService]
})
export class ConversationComponent implements OnInit, OnDestroy {

    peerName: string;
    conversationId: string;
    isScreenSharingActive = false;

    private subscriptions: Subscription[] = [];
    private roomIsFull = false;

    constructor(private entranceService: EntranceService,
                private callbackService: CallbackService,
                private peersService: PeersService,
                private animationService: AnimationService,
                private chatService: ChatService,
                private changeDetectorRef: ChangeDetectorRef,
                private router: Router,
                private zone: NgZone) {
        this.handleSignalingError = this.handleSignalingError.bind(this);
    }

    ngOnInit() {
        const entranceData = this.entranceService.entranceData;
        this.peerName = entranceData.peerName;
        this.conversationId = entranceData.conversationId;
        console.log(`[DEBUG] - entrance data: ${JSON.stringify(entranceData)}`);

        joinRoom(this.conversationId, this.peerName, {
            videoElementId: 'video',
            sessionEstablishedCallback: async (media: MediaCommunication) => this.callbackService.sessionEstablished.next(media),
            incomingCallCallback: (media: MediaCommunication) => this.askForCallAccept(media),
            sessionEndedCallback: async (media: MediaCommunication) => this.callbackService.sessionEnded.next(media),
            callRejectedCallback: async (media: MediaCommunication) => this.callbackService.callRejected.next(media),
            errorCallback: async (errorCode: string) => this.callbackService.errorOccurred.next(errorCode),
            joinedMembersCallback: async (members: string[]) => this.callbackService.joinedMembers.next(members),
            leftMembersCallback: async (members: string[]) => this.callbackService.leftMembers.next(members),
            newChatMessageCallback: async (from: string, message: string) =>
                this.callbackService.newChatMessage.next({from: from, message: message})
        });

        this.subscriptions.push(this.animationService.animateScreenSharingSubject.subscribe(isScreenSharingActive => {
            this.isScreenSharingActive = isScreenSharingActive;
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }));
        this.subscriptions.push(this.callbackService.errorOccurred.subscribe(this.handleSignalingError));
        this.animationService.expandChat(ChatState.COLLAPSED);
    }

    ngOnDestroy(): void {
        this.animationService.expandChat(ChatState.HIDDEN);
        this.subscriptions.forEach(s => s.unsubscribe());

        if (!this.peersService.isRoomEmptyExceptMe()) {
            hangup({
                [Medium[Medium.AUDIO]]: Communication[Communication.SHARE_AND_RECEIVE],
                [Medium[Medium.SCREENSHARING]]: Communication[Communication.SHARE_AND_RECEIVE]
            });
        }
        if (!this.roomIsFull) {
            leaveCurrentRoom();
        }
        this.roomIsFull = false;
        this.entranceService.entranceData.peerName = undefined;
    }

    private async askForCallAccept(media: MediaCommunication): Promise<boolean> {
        // na ten moment akceptujemy wszystko jak leci i wysylamy informacje że my rowniez dzwonimy
        const acceptedSharingMedia: string[] = [];
        const mediaKeys: string[] = Object.keys(media);

        mediaKeys.forEach(key => {
            if (CommunicationUtil.isSharing(media[key])) {
                acceptedSharingMedia.push(key);
            }
        });

        if (acceptedSharingMedia.length > 0) {
            this.callbackService.acceptedSharingMedia.next(acceptedSharingMedia);
        }
        return true;
    }

    private handleSignalingError(errorCode: string) {
        if (errorCode === SignalingError[SignalingError.ROOM_IS_FULL]) {
            this.roomIsFull = true;
            this.zone.run(() => this.router.navigate(['/'])).then();
        }
    }
}
