import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomePageComponent} from './home/home-page.component';
import {WaitingRoomComponent} from 'src/app/waiting-room/waiting-room.component';
import {ConversationComponent} from './conversation/conversation.component';
import {ConversationGuard} from 'src/app/conversation/conversation.guard';
import {WaitingRoomGuard} from 'src/app/waiting-room/waiting-room.guard';
import {EntranceWay} from './entrance/entrance.service';

const routes: Routes = [
    {
        path: 'join/:id',
        component: WaitingRoomComponent,
        canActivate: [WaitingRoomGuard],
        data: {
            animation: 'WaitingRoom',
            entranceWay: EntranceWay.JOIN
        }
    },
    {
        path: 'join',
        component: WaitingRoomComponent,
        data: {
            animation: 'WaitingRoom',
            entranceWay: EntranceWay.JOIN
        }
    },
    {
        path: 'new',
        component: WaitingRoomComponent,
        data: {
            animation: 'WaitingRoom',
            entranceWay: EntranceWay.NEW
        }
    },
    {
        path: 'conversation/:id',
        component: ConversationComponent,
        canActivate: [ConversationGuard],
    },
    {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full',
        data: {animation: 'Home'}
    },
    {
        path: '**',
        redirectTo: '/',
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
