import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {fadeAnimation} from '../app.animations';

@Component({
    selector: 'app-home-page',
    animations: [fadeAnimation],
    template: `
        <div class="vertical-center">
            <span class="headline">Connecting client's needs with your business</span>
            <div class="button-bar">
                <button class="button button-solid" mat-raised-button routerLink="/new">
                    New conversation
                </button>
                <button class="button button-outline" mat-raised-button routerLink="/join">
                    Join conversation
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./home-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
