import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AnimationService} from '../animation/animation.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-video',
    template: `
        <div class="navbar-spacer"></div>
        <video id="video"
               poster="/assets/transparent.png"
               class="video-animation"
               [ngClass]="{
                'video-appear': screenSharingAppear
                }"
               autoplay playsinline controls></video>
    `,
    styleUrls: ['video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements OnInit, OnDestroy {
    screenSharingAppear = false;
    private readonly subscriptions: Subscription[] = [];

    constructor(private animationService: AnimationService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscriptions.push(this.animationService.animateScreenSharingSubject.subscribe(isScreensharingActive => {
            this.screenSharingAppear = isScreensharingActive;

            // @ts-ignore
            if (!this.screenSharingAppear && document.fullscreenElement) {
                document.exitFullscreen().then();
            }
            this.changeDetectorRef.detectChanges();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
