import {Injectable} from '@angular/core';
import {EntranceModel} from 'src/app/entrance/entrance.model';

@Injectable({
    providedIn: 'root'
})
export class EntranceService {
    public entranceData: EntranceModel = new EntranceModel(undefined, undefined);

    constructor() {
    }
}

export enum EntranceWay {
    NEW,
    JOIN
}
