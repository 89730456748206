import {Component} from '@angular/core';
import {fadeAnimation} from './app.animations';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-root',
    animations: [fadeAnimation],
    template: `
        <div class="whole-page">
            <div class="page">
                <app-navbar></app-navbar>
                <span class="main" [@fadeAnimation]="prepareRoute(outlet)">
                    <router-outlet #outlet="outlet"></router-outlet>
                </span>
                <app-info></app-info>
            </div>
            <app-chat></app-chat>
        </div>
    `,
    styles: [`
        :host {
            height: 100%;
            width: 100%;
        }

        .whole-page {
            position: relative;
            display: flex;
            flex-direction: row;
            height: 100%;
            width: 100%;

            background-image: url("../assets/dish.jpg");
            background-position: center bottom;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
        }

        .page {
            position: relative;

            flex-grow: 1;
            flex-shrink: 1;

            display: flex;
            flex-direction: column;
            height: 100%;
            resize: horizontal;
        }

        .main {
            width: 100%;
            height: 100%;
        }

        app-info {
            z-index: 100;
        }
    `]
})
export class AppComponent {

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
