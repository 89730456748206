export enum Communication {
    SHARE, RECEIVE, SHARE_AND_RECEIVE
}

export class CommunicationUtil {

    static isSharing(communication: string | undefined): boolean {
        return communication !== undefined &&
            (communication === Communication[Communication.SHARE]
                || communication === Communication[Communication.SHARE_AND_RECEIVE]);
    }

    static isReceiving(communication: string | undefined): boolean {
        return communication !== undefined &&
            (communication === Communication[Communication.RECEIVE]
                || communication === Communication[Communication.SHARE_AND_RECEIVE]);
    }
}
