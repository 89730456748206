import {Medium} from 'src/app/conversation/tracks/medium';
import {SignalingError} from 'src/app/conversation/tracks/signaling-error';

export class MessageGenerator {

    public static generateRejectedCallMessage(media: string[]): NotificationMessage {
        if (media.length === 1) {
            return media[0] === Medium[Medium.AUDIO]
                ? new NotificationMessage('Audio call rejected')
                : new NotificationMessage('Screensharing rejected');
        } else {
            return new NotificationMessage('Call rejected');
        }
    }

    public static generateErrorMessage(errorCode: string): NotificationMessage {
        if (errorCode === SignalingError[SignalingError.NOT_IN_ROOM]) {
            return new NotificationMessage(`There is no one to talk to, please wait for another person`);
        }
        if (errorCode === SignalingError[SignalingError.ROOM_IS_FULL]) {
            return new NotificationMessage(
                `Conversation has too many members, try later, join another conversation or create a new one`,
                7000
            );
        }
        return new NotificationMessage(`Error occurred. Please try again later`);
    }
}

export class NotificationMessage {
    constructor(public message: string, public duration: number = 3000) {
    }
}
