import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SupportService {

    public static readonly CHROME_MIN_VER = 72;
    public static readonly FIREFOX_MIN_VER = 66;
    public static readonly EDGE_MIN_VER = 18;
    public isSupported;
    public isMicConnected = true;

    constructor() {
        this.isSupported = this.isWebRTCSupported();
        if (this.isSupported) {
            this.checkIfMicConnected().then(connected => this.isMicConnected = connected);
        }
    }

    private isWebRTCSupported(): boolean {
        const browser: Browser = this.detectBrowser(window);
        const browserName = BrowserName[browser.name].toLowerCase();
        console.log(`Detected browser: ${JSON.stringify(browserName)}, version: ${JSON.stringify(browser.version)}`);
        switch (browser.name) {
            case BrowserName.CHROME:
                return browser.version >= SupportService.CHROME_MIN_VER;
            case BrowserName.FIREFOX:
                return browser.version >= SupportService.FIREFOX_MIN_VER;
            case BrowserName.EDGE:
                return browser.version >= SupportService.EDGE_MIN_VER;
            case BrowserName.SAFARI:
            case BrowserName.NOT_A_BROWSER:
            case BrowserName.NOT_A_SUPPORTED_BROWSER:
                return false;
        }
    }

    private detectBrowser(window): Browser {
        const {navigator} = window;

        // Returned result object.
        const result = new Browser(BrowserName.NOT_A_SUPPORTED_BROWSER, 0);

        // Fail early if it's not a browser
        if (typeof window === 'undefined' || !window.navigator) {
            result.name = BrowserName.NOT_A_BROWSER;
            return result;
        }

        if (navigator.mozGetUserMedia) { // Firefox.
            result.name = BrowserName.FIREFOX;
            result.version = this.extractVersion(navigator.userAgent,
                /Firefox\/(\d+)\./, 1);
        } else if (navigator.webkitGetUserMedia ||
            (window.isSecureContext === false && window.webkitRTCPeerConnection &&
                !window.RTCIceGatherer)) {
            // Chrome, Chromium, Webview, Opera.
            // Version matches Chrome/WebRTC version.
            // Chrome 74 removed webkitGetUserMedia on http as well so we need the
            // more complicated fallback to webkitRTCPeerConnection.
            result.name = BrowserName.CHROME;
            result.version = this.extractVersion(navigator.userAgent,
                /Chrom(e|ium)\/(\d+)\./, 2);
        } else if (navigator.mediaDevices &&
            navigator.userAgent.match(/Edge\/(\d+).(\d+)$/)) { // Edge.
            result.name = BrowserName.EDGE;
            result.version = this.extractVersion(navigator.userAgent,
                /Edge\/(\d+).(\d+)$/, 1);
        } else if (window.RTCPeerConnection &&
            navigator.userAgent.match(/AppleWebKit\/(\d+)\./)) { // Safari.
            result.name = BrowserName.SAFARI;
            result.version = this.extractVersion(navigator.userAgent,
                /Version\/(\d+)\./, 1);
        } else { // Default fallthrough: not supported.
            result.name = BrowserName.NOT_A_SUPPORTED_BROWSER;
            return result;
        }

        return result;
    }

    private extractVersion(userAgent: string, expr: RegExp, pos: number) {
        const match = userAgent.match(expr);
        return match && match.length >= pos && parseInt(match[pos], 10);
    }

    private async checkIfMicConnected() {
        const mediaDevices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
        return mediaDevices.some(device => device.kind === 'audioinput');
    }
}

class Browser {
    constructor(public name: BrowserName, public version: number) {
    }
}

enum BrowserName {
    CHROME, FIREFOX, EDGE, SAFARI, NOT_A_BROWSER, NOT_A_SUPPORTED_BROWSER
}
