import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomePageComponent} from './home/home-page.component';
import {NavbarComponent} from './navbar/navbar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InfoComponent} from './home/info.component';
import {WaitingRoomComponent} from './waiting-room/waiting-room.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConversationComponent} from './conversation/conversation.component';
import {CopyComponent} from './navbar/copy.component';
import {IncomingCallDialogComponent} from './conversation/dialog/incoming-call-dialog.component';
import {TrackSelectorComponent} from 'src/app/conversation/tracks/track-selector.component';
import {PeersComponent} from 'src/app/conversation/peers/peers.component';
import { VideoComponent } from 'src/app/conversation/video/video.component';
import { BrickComponent } from 'src/app/navbar/brick/brick.component';
import {ChatComponent} from 'src/app/conversation/chat/chat.component';
import {ChatFormComponent} from 'src/app/conversation/chat/form/chat.form.component';
import {ChatMessagesComponent} from 'src/app/conversation/chat/messages/chat.messages.component';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        NavbarComponent,
        InfoComponent,
        WaitingRoomComponent,
        ConversationComponent,
        IncomingCallDialogComponent,
        TrackSelectorComponent,
        CopyComponent,
        PeersComponent,
        VideoComponent,
        BrickComponent,
        ChatComponent,
        ChatFormComponent,
        ChatMessagesComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatInputModule,
        MatStepperModule,
        ReactiveFormsModule,
        FormsModule,
        MatSnackBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatRippleModule,
    ],
    providers: [],
    entryComponents: [
        IncomingCallDialogComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
