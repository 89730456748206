import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ChatService} from 'src/app/conversation/chat/chat.service';
import {Subscription} from 'rxjs';
import {AnimationService} from 'src/app/conversation/animation/animation.service';
import {ChatState} from 'src/app/conversation/chat/chat-state.enum';

@Component({
    selector: 'app-chat-form',
    template: `
        <div class="component-wrapper">
            <textarea matInput
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      [(ngModel)]="message"
                      (keyup.enter)="sendMessage()"
                      (keydown.enter)="clearEnter($event)"
                      placeholder="Type a message"
                      maxlength="{{getMaxLength()}}"
                      #messageField></textarea>

            <button mat-icon-button
                    (click)="sendMessage()"
                    [disabled]="!validForm()"
                    #messageField>
                <mat-icon>send</mat-icon>
            </button>
        </div>
    `,
    styleUrls: ['./chat.form.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ChatFormComponent implements OnInit, OnDestroy {
    static readonly MAX_LENGTH = 1024;
    private readonly subscriptions: Subscription[] = [];

    @ViewChild('messageField')
    private messageField: ElementRef;

    message: string;

    constructor(private chatService: ChatService,
                private animationService: AnimationService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.messageField.nativeElement.focus();
        this.subscriptions.push(this.animationService.animateExpandChat.subscribe(this.clearMessageField.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public sendMessage() {
        if (this.validForm()) {
            this.chatService.sendMessage(this.message);
            this.message = '';
            this.changeDetectorRef.detectChanges();
            this.messageField.nativeElement.focus();
        }
    }

    public clearEnter(event: Event) {
        event.stopPropagation();
        return false;
    }

    clearMessageField(enabled: ChatState) {
        if (enabled === ChatState.HIDDEN) {
            this.message = '';
        }
    }

    validForm(): boolean {
        const messageLength = this.message.trim().length;
        return messageLength > 0 && messageLength <= ChatFormComponent.MAX_LENGTH;
    }

    getMaxLength(): number {
        return ChatFormComponent.MAX_LENGTH;
    }

}
