import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {CallbackService} from '../callback/callback.service';
import {Peer} from './peer';

@Injectable()
export class PeersService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    public peers: Peer[] = [];

    public onChange: Subject<void> = new Subject<void>();


    constructor(private callbackService: CallbackService) {
        this.addJoinedMembers = this.addJoinedMembers.bind(this);
        this.removeLeftMembersExceptMe = this.removeLeftMembersExceptMe.bind(this);

        this.subscriptions.push(this.callbackService.joinedMembers.subscribe(this.addJoinedMembers));
        this.subscriptions.push(this.callbackService.leftMembers.subscribe(this.removeLeftMembersExceptMe));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public isRoomEmptyExceptMe() {
        return this.peers.filter(p => !p.me).length === 0;
    }

    public addMe(name: string) {
        const indexToEdit = this.peers.findIndex(p => p.me);
        if (indexToEdit > -1) {
            this.peers[indexToEdit].name = name;
            this.peers[indexToEdit].muted = false;
        } else {
            this.peers.push(new Peer(name, false, true));
        }
        this.onChange.next();
    }

    private addJoinedMembers(members: string[]) {
        if (members.length > 0) {
            members
            .map(m => new Peer(m, false))
            .forEach(m => this.peers.push(m));

            this.onChange.next();
        }
    }

    private removeLeftMembersExceptMe(members: string[]) {
        if (members.length > 0) {
            members
            .forEach(m => {
                const indexToSplice = this.peers.findIndex(p => !p.me && p.name === m);
                if (indexToSplice > -1) {
                    this.peers.splice(indexToSplice, 1);
                }
            });

            this.onChange.next();
        }
    }
}
