import {animate, group, query, style, transition, trigger} from '@angular/animations';

const steps = [
    query(':enter, :leave', style({position: 'fixed', width: '100%'}), {optional: true}),
    group([
        query(':enter', [
            style({opacity: 0}),
            animate('0.45s ease-in-out', style({opacity: 100}))
        ], {optional: true}),
        query(':leave', [
            style({opacity: 100}),
            animate('0.1s ease-in-out', style({opacity: 0}))
        ], {optional: true}),
    ])
];

export const fadeAnimation = trigger('fadeAnimation', [
    transition('Home <=> WaitingRoom', steps)
]);
