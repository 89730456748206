import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ChatService, Message} from 'src/app/conversation/chat/chat.service';
import {Subscription} from 'rxjs';
import {AnimationService} from 'src/app/conversation/animation/animation.service';
import {ChatState} from 'src/app/conversation/chat/chat-state.enum';

@Component({
    selector: 'app-chat-messages',
    template: `
        <div class="component-wrapper" #content>
            <div *ngFor="let message of messages; let i = index"
                 class="message"
                 [ngClass]="{
                    'message-me': message.peer.me,
                    'message-other': !message.peer.me
                 }">
                <div class="content" *ngIf="message.peer.me">{{message.message}}</div>
                <div class="icon"><span class="letter">{{message.peer.name.substr(0, 1)}}</span></div>
                <div class="content" *ngIf="!message.peer.me">{{message.message}}</div>
            </div>
        </div>
    `,
    styleUrls: ['./chat.messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ChatMessagesComponent implements OnInit, OnDestroy {
    private readonly subscriptions: Subscription[] = [];

    public messages: Message[] = [];

    @ViewChild('content')
    private content: ElementRef;

    constructor(private chatService: ChatService,
                private animationService: AnimationService,
                private changeDetectorRef: ChangeDetectorRef) {
        this.receive = this.receive.bind(this);
    }

    ngOnInit() {
        this.subscriptions.push(this.chatService.messagesEvent.subscribe(this.receive));
        this.subscriptions.push(this.animationService.animateExpandChat.subscribe(this.clearMessages.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private receive(message: Message) {
        this.messages.push(message);
        this.changeDetectorRef.detectChanges();
        const contentElement = this.content.nativeElement as HTMLDivElement;
        contentElement.scrollTop = contentElement.scrollHeight;
    }

    clearMessages(enabled: ChatState) {
        if (enabled === ChatState.HIDDEN) {
            this.messages = [];
        }
    }
}
